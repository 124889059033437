const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      email: 'The email format is invalid.',
      password: {
        minLength: 'Password must be at least 8 characters.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
    },
    modal: {
      logout_title: 'Confirm Logout',
      logout_subtitle: 'Are you sure you want to logout?',
      logout_button: 'Logout',
      cancel: 'Cancel',
      ok: 'OK',
      contact_admin_title: 'Contact System Admin',
      contact_admin_subtitle:
        'Please contact the system administrator to proceed on registering as List Company User',
      forgot_password_title: 'Check your Email',
      forgot_password_subtitle:
        'A link to reset your password has been sent to your registered address',
      reset_password_title: 'Reset Password Successfully',
      reset_password_subtitle: 'Please re-login using your new password.',
    },
    labels: {
      first_name: 'First Name',
      last_name: 'Last Name',
      account_type: 'Account Type',
      furigana_name: 'Furigana Name',
      furigana_first_name: 'Furigana First Name',
      furigana_last_name: 'Furigana Last Name',
      phone_number: 'Phone Number',
      company_name: 'Company Name',
      company_address: 'Company Address',
      prefecture: 'Prefecture',
      address: 'address',
      industry: 'Industry',
      department_job_title: 'Department/Title',
      number_of_employees: 'Number of Employees',
      job_title: 'Title',
      year_established: 'Year Established',
      url: 'Url',
      login: 'Login',
      signup: 'Signup',
      remember_me: 'Remember Me',
      forgot_password: 'Forgot Your Password?',
      email_address: 'Email Address',
      password: 'Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      update: 'Update',
      save: 'Save',
      add_new: 'Add New',
      reset_password: 'Reset Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      viewer_login: 'Login as Viewer User',
      company_login: 'Login as Company User',
      image_upload: 'Image Upload',
      delete_image: 'Delete Image',
      select_account: 'Select Account to Register',
      register_confirmation_message:
        'Please choose which account type below would you like to proceed with the registration.',
      register_viewer: 'Register as Viewer',
      register_listing_company: 'Register as Listing Company',
      no_account: "Don't have an account?",
      register: 'Register',
      reset_pass: 'Send Reset Password',
      update_pass: 'Update Password',
    },
    placeholders: {
      email: 'Email',
      password: 'Password',
      forgot_email: 'Please enter your registered email address',
      reset_password: 'Please enter your new password',
      confirm_reset_password: 'Please enter again the new password',
    },
    pages: {
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
        account_registration: 'Account Registration',
        of_system: '本システムの',
        agree: 'に同意します',
        privacy_policy: ' プライバシーポリシーおよび利用規約',
      },
      forgot_password: {
        sub_heading: 'Please enter registered email address to reset your password.',
        success: 'Check your inbox for instructions on how to reset your password.',
      },
      reset_password: {
        sub_heading: 'Enter your new password to reset your credenials.',
        success: 'The password has been updated successfully.',
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        new_users: 'New Users',
        total_sales: 'Total Sales',
        total_orders: 'Total Orders',
      },
      not_found: {
        title: 'Page Not Found',
        sub_heading:
          'The page you are looking for may have been deleted or moved to another location.',
        back_to_top: 'Back to Top Page',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Edit Profile',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Get to know the ideal IT company for you',
        sub_heading: '150, 890 companies listed here! Find the right company for you.',
        why_heading: 'Why use Base Template?',
        docker: {
          heading: 'Flexible Environment',
          description:
            'Eliminate the "it works on my machine" problem once and for all. Spend less time setting up environments, debugging environment-specific issues, and a more portable and easy-to-set-up codebase.',
        },
        react: {
          heading: 'Fast & Intuitive UI',
          description:
            'ReactJS is extremely intuitive to work with and provides interactivity to the layout of any UI. It is composable so we can utilize these components and integrate them into one place. Hence the code becomes a lot more maintainable and flexible.',
        },
        laravel: {
          heading: 'Powerful API',
          description:
            "Utilizing Laravel's API feature for easy backend API development. It's quick, simple with easy 3rd Party integrations and libraries.",
        },
        our_customers_heading: 'Our Clients',
        reviews_heading: 'What our clients say',
        see_all_reviews: 'See All Reviews',
        call_to_action: 'Accelerate your application development today!',

        // SaasMart
        category: 'Category',
        all_categories: 'All categories',
        ranking: 'Ranking',
        see_all: 'See All',
        news_articles: 'News/Articles',
        all_articles: 'All articles',
        user_guide: 'User Guide',
        search: 'Search',
        search_placeholder: 'Search for Service Name • Category Name (Salesforce, Chat, etc.)',
      },
      services: {
        title: 'Service Management',
      },
      about: {
        main_heading: 'Our Story',
        sub_heading:
          'We work together to design, create and produce works that we are proud of for those we believe in.',
        meet_the_team: 'Meet the team',
        team_description:
          'Thoughtfulness, originality, and attention to detail are the basis for every product we design, build, and market.',
        our_mission: 'Our Mission',
        mission_description:
          'Our mission is to spread the excellence of technology with quality service and products valuing the business trend and proposition with people centric culture and behavior.',
        our_activities: 'Our Activities',
        activities_description: 'Never get so busy making a living that you forget to make a life.',
      },
    },
    menu: {
      home: 'Home',
      about: 'About',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      dashboard: 'Dashboard',
      users: 'Users',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      support: 'Documentation',
      styleguide: 'Styleguide',
      category: 'Category',
      ranking: 'Ranking',
      user_guide: 'User Guide',
      news: 'News/Articles',
      it_provider_login: 'IT Provider Login',
      viewer_login: 'Viewer Login',
      viewer_registration: 'Viewer Registration',
    },
    table: {
      no_data: 'No data.',
    },
    footer: {
      company_profile: 'Company Profile',
      privacy_policy: 'Privacy Policy',
      terms: 'Terms of Service',
      subtitle: 'IT Company Services Comparison Site',
      caption:
        'Copying, public transmission, modification, modification, reprinting, etc. of content without ' +
        'the permission of the Company, the original author, or other right holder is prohibited by copyright law,' +
        'except as permitted by copyright law.',
      copyright: 'SaaSMart. All Rights Reserved.',
    },
  },
};

export default en;
