// metrics in "rem" not "px"
const pxToRem = (val) => `${val / 16}rem`;

/**
 * MUI Typography
 * @see https://mui.com/material-ui/customization/typography/
 */
const typography = {
  fontFamily: '"Noto Sans JP", sans-serif',
  h1: {
    fontSize: pxToRem(48),
    fontWeight: 700,
  },
  h2: {
    fontSize: pxToRem(42),
    fontWeight: 700,
  },
  h3: {
    fontSize: pxToRem(36),
    fontWeight: 700,
  },
  h4: {
    fontSize: pxToRem(30),
    fontWeight: 700,
  },
  h5: {
    fontSize: pxToRem(24),
    fontWeight: 700,
  },
  h6: {
    fontSize: pxToRem(18),
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: pxToRem(14),
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  body1: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  body2: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  caption: {
    fontSize: pxToRem(12),
    fontWeight: 300,
  },
  overline: {
    fontSize: pxToRem(10),
    fontWeight: 300,
  },

  // bold
  bold9: {
    fontSize: pxToRem(9),
    fontWeight: 700,
  },
  bold12: {
    fontSize: pxToRem(12),
    fontWeight: 700,
  },
  bold13: {
    fontSize: pxToRem(13),
    fontWeight: 700,
  },
  bold14: {
    fontSize: pxToRem(14),
    fontWeight: 700,
  },
  bold15: {
    fontSize: pxToRem(15),
    fontWeight: 700,
  },
  bold16: {
    fontSize: pxToRem(16),
    fontWeight: 700,
  },
  bold22: {
    fontSize: pxToRem(22),
    fontWeight: 700,
  },
  bold20: {
    fontSize: pxToRem(20),
    fontWeight: 700,
  },
  bold28: {
    fontSize: pxToRem(28),
    fontWeight: 700,
  },
  bold30: {
    fontSize: pxToRem(30),
    fontWeight: 700,
  },
  bold40: {
    fontSize: pxToRem(40),
    fontWeight: 700,
  },
  bold45: {
    fontSize: pxToRem(45),
    fontWeight: 700,
  },
  bold60: {
    fontSize: pxToRem(60),
    fontWeight: 700,
  },
  bold70: {
    fontSize: pxToRem(70),
    fontWeight: 700,
  },
  bold90: {
    fontSize: pxToRem(90),
    fontWeight: 700,
  },
  bold115: {
    fontSize: pxToRem(115),
    fontWeight: 700,
  },
  bold120: {
    fontSize: pxToRem(120),
    fontWeight: 700,
  },
  bold140: {
    fontSize: pxToRem(140),
    fontWeight: 700,
  },
  bold150: {
    fontSize: pxToRem(150),
    fontWeight: 700,
  },

  // medium
  medium7: {
    fontSize: pxToRem(7),
    fontWeight: 500,
  },
  medium8: {
    fontSize: pxToRem(8),
    fontWeight: 500,
  },
  medium9: {
    fontSize: pxToRem(9),
    fontWeight: 500,
  },
  medium10: {
    fontSize: pxToRem(10),
    fontWeight: 500,
  },
  medium11: {
    fontSize: pxToRem(11),
    fontWeight: 500,
  },
  medium12: {
    fontSize: pxToRem(12),
    fontWeight: 500,
  },
  medium13: {
    fontSize: pxToRem(13),
    fontWeight: 500,
  },
  medium14: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  medium15: {
    fontSize: pxToRem(15),
    fontWeight: 500,
  },
  medium16: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  medium17: {
    fontSize: pxToRem(17),
    fontWeight: 500,
  },
  medium18: {
    fontSize: pxToRem(18),
    fontWeight: 500,
  },
  medium20: {
    fontSize: pxToRem(20),
    fontWeight: 500,
  },
  medium22: {
    fontSize: pxToRem(22),
    fontWeight: 500,
  },
  medium24: {
    fontSize: pxToRem(24),
    fontWeight: 500,
  },
  medium25: {
    fontSize: pxToRem(25),
    fontWeight: 500,
  },
  medium26: {
    fontSize: pxToRem(26),
    fontWeight: 500,
  },
  medium28: {
    fontSize: pxToRem(28),
    fontWeight: 500,
  },
  medium32: {
    fontSize: pxToRem(32),
    fontWeight: 500,
  },
  medium35: {
    fontSize: pxToRem(35),
    fontWeight: 500,
  },

  // regular
  regular9: {
    fontSize: pxToRem(9),
    fontWeight: 400,
  },
  regular10: {
    fontSize: pxToRem(10),
    fontWeight: 400,
  },
  regular11: {
    fontSize: pxToRem(11),
    fontWeight: 400,
  },
  regular12: {
    fontSize: pxToRem(12),
    fontWeight: 400,
  },
  regular13: {
    fontSize: pxToRem(13),
    fontWeight: 400,
  },
  regular14: {
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  regular15: {
    fontSize: pxToRem(15),
    fontWeight: 400,
  },
  regular16: {
    fontSize: pxToRem(16),
    fontWeight: 400,
  },
  regular18: {
    fontSize: pxToRem(18),
    fontWeight: 400,
  },
  regular20: {
    fontSize: pxToRem(20),
    fontWeight: 400,
  },
  regular25: {
    fontSize: pxToRem(25),
    fontWeight: 400,
  },
};

export default typography;
