/**
 * MUI Palette Override
 * @see https://mui.com/material-ui/customization/palette/
 */
const palette = {
  primary: {
    main: '#5086C6',
    contrastText: '#fff',
  },
  secondary: {
    main: '#F3E536',
    contrastText: '#fff',
  },
  success: {
    main: '#70A37F',
    contrastText: '#fff',
  },
  error: {
    main: '#FE6D73',
    contrastText: '#fff',
  },
  warning: {
    main: '#F3C969',
    contrastText: '#fff',
  },
  info: {
    main: '#9DD1F1',
    contrastText: '#fff',
  },
  transparent: {
    main: '#fff',
    dark: '#888',
  },
  edit: {
    main: '#1A3681',
    contrastText: '#888',
  },
  reset: {
    main: '#FF0000',
    contrastText: '#888',
  },
  text: {
    main: '#414141',
    contrastText: '#888',
  },
  public: {
    main: '#1FD300',
    contrastText: '#888',
  },
  faded: {
    main: '#81aee1',
    contrastText: '#fff',
  },
  background: {
    main: '#fff',
    dark: '#BEBEBE',
  },
  header: {
    text: '#044B9F',
    bg: '#C9C9C9',
  },
  header2: {
    text: '#044B9F',
    bg: '#F0F0F0',
  },
  disabled: {
    text: '#868686',
    button: '#C5C5C5',
  },
  flag: '#B2C7E1',
  highlight: '#d7e2ef',
  image: '#BDBDBD',
  border: '#707070',
  common: '#474747',
  tag: '#99C5F8',
  step: '#3E6AC3',
  divider: 'rgba(0,0,0,0.05)',
};

export default palette;
