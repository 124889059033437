import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ThemeProvider } from '@mui/material/styles';
import Router from './router';
import theme from './theme';

function App() {
  useEffect(() => {
    const path = location.pathname + location.search;
    ReactGA.send({
      hitType: 'pageview',
      page: path,
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
