const routes = [
  // Dont Remove. Handle 404 Pages
  {
    path: '*',
    component: 'pages/guest/NotFound',
    auth: false,
    isPrivate: false,
  },
  {
    path: '/',
    component: 'pages/guest/Landing',
    auth: false,
  },
  {
    path: '/dashboard',
    component: 'pages/authenticated/Dashboard',
    auth: true,
  },
  {
    path: '/about',
    component: 'pages/guest/About',
    auth: false,
  },
  {
    path: '/signup',
    component: 'pages/guest/Signup',
    auth: false,
    isPrivate: true,
  },
  {
    path: '/login/:role',
    component: 'pages/guest/Login',
    auth: false,
    isPrivate: true,
  },
  {
    path: '/forgot-password/:role',
    component: 'pages/guest/ForgotPassword',
    auth: false,
    isPrivate: true,
  },
  {
    path: '/password/reset',
    component: 'pages/guest/ResetPassword',
    auth: false,
    isPrivate: true,
  },
  {
    path: '/password/set',
    component: 'pages/guest/SetPassword',
    auth: false,
    isPrivate: true,
  },
  {
    path: '/activate',
    component: 'pages/guest/Activate',
    auth: false,
  },
  {
    path: '/profile',
    component: 'pages/authenticated/Profile',
    auth: true,
  },
  {
    path: '/terms',
    component: 'pages/guest/Terms',
    auth: false,
  },
  {
    path: '/commercial-transactions',
    component: 'pages/guest/CommercialTransactions',
    auth: false,
  },
  {
    path: '/terms-company',
    component: 'pages/guest/TermsCompany',
    auth: false,
  },
  {
    path: '/users',
    component: 'pages/authenticated/admin/Users',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/announcements/new',
    component: 'pages/authenticated/admin/announcement/AnnouncementAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/announcements/update/:id',
    component: 'pages/authenticated/admin/announcement/AnnouncementAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/admin/announcements',
    component: 'pages/authenticated/admin/announcement/Announcements',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/announcements',
    component: 'pages/authenticated/announcement/Announcements',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/announcements/details/:id',
    component: 'pages/authenticated/announcement/AnnouncementDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/news/new',
    component: 'pages/authenticated/admin/news/NewsAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/news/update/:id',
    component: 'pages/authenticated/admin/news/NewsAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/admin/news',
    component: 'pages/authenticated/admin/news/News',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/news/details/:id',
    component: 'pages/guest/news/NewsDetails',
    auth: false,
  },
  {
    path: '/news',
    component: 'pages/guest/news/News',
    auth: false,
  },
  {
    path: '/admin/topics',
    component: 'pages/authenticated/admin/topics/Topics',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/topics/new',
    component: 'pages/authenticated/admin/topics/TopicsAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/topics/update/:id',
    component: 'pages/authenticated/admin/topics/TopicsAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/topics/details/:id',
    component: 'pages/guest/topics/TopicDetails',
    auth: false,
  },
  {
    path: '/topics',
    component: 'pages/guest/topics/Topics',
    auth: false,
  },
  {
    path: '/admin/user-guides',
    component: 'pages/authenticated/admin/user_guide/UserGuides',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/user-guides',
    component: 'pages/guest/user_guide/UserGuides',
    auth: false,
  },
  {
    path: '/admin/interviews',
    component: 'pages/authenticated/admin/interviews/Interviews',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/admin/interviews/details/:slug',
    component: 'pages/authenticated/admin/interviews/InterviewDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/interviews/new',
    component: 'pages/authenticated/admin/interviews/InterviewAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/interviews/update/:slug',
    component: 'pages/authenticated/admin/interviews/InterviewAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/interviews',
    component: 'pages/guest/interview/Interviews',
    auth: false,
  },
  {
    path: '/interviews/details/:slug',
    component: 'pages/guest/interview/InterviewDetails',
    auth: false,
  },
  {
    path: '/admin/categories',
    component: 'pages/authenticated/admin/categories/Categories',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/categories/update',
    component: 'pages/authenticated/admin/categories/CategoriesAddEdit',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/reviews',
    component: 'pages/authenticated/reviews/viewer/Reviews',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/reviews/services',
    component: 'pages/authenticated/reviews/company/Services',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/reviews/services/:slug',
    component: 'pages/authenticated/reviews/company/Reviews',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/integrations',
    component: 'pages/authenticated/Integrations',
    auth: true,
  },
  {
    path: '/faq',
    component: 'pages/guest/Faq',
    auth: false,
  },
  {
    path: '/inquiry',
    component: 'pages/guest/Inquiry',
    auth: false,
  },
  {
    path: '/privacy-policy',
    component: 'pages/guest/PrivacyPolicy',
    auth: false,
  },
  {
    path: '/account-information',
    component: 'pages/authenticated/AccountInformation',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/change-email',
    component: 'pages/authenticated/ChangeEmail',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/change-password',
    component: 'pages/authenticated/ChangePassword',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/download-history',
    component: 'pages/authenticated/DownloadHistory',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/download-history/details',
    component: 'pages/authenticated/history/HistoryDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/services',
    component: 'pages/authenticated/Services',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/services/create',
    component: 'pages/authenticated/service/ServiceDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/services/update/:id',
    component: 'pages/authenticated/service/ServiceDetails',
    auth: true,
    isPrivate: true,
  },
  {
    path: '/service/details/:slug',
    component: 'pages/guest/service/ServiceInformation',
    auth: false,
  },
  {
    path: '/service/:slug/reviews',
    component: 'pages/guest/reviews/Reviews',
    auth: false,
  },
  {
    path: '/search',
    component: 'pages/guest/Search',
    auth: false,
  },
  {
    path: '/service/compare',
    component: 'pages/guest/service/ServiceComparison',
    auth: false,
  },
  {
    path: '/update-account/:id',
    component: 'pages/authenticated/admin/EditAccount',
    auth: true,
  },
  {
    path: '/service-material/download',
    component: 'pages/guest/service/ServiceMaterialsDownload',
    auth: false,
  },
  {
    path: '/service-material/finish',
    component: 'pages/guest/FinishDownload',
    auth: false,
  },
];

// Don't include styleguide in production routes
if (process.env.ENVIRONMENT !== 'production') {
  routes.push({
    path: '/styleguide',
    component: 'pages/guest/Styleguide',
    auth: false,
  });
}

export default routes;
